//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VenueCreateModal from '@/components/EventCreate/VenueCreateModal.vue';
import { Venues } from '@/services/api.service';
import * as moment from 'moment-timezone';

const timezones = moment.tz.names();

export default {
  name: 'VenueComponent',
  props: ['errors', 'event'],
  components: {
    VenueCreateModal,
  },
  data() {
    return {
      showCreateVenueModal: false,
      specialVenues: [],
      specialVenuesLoaded: false,
      specialVenueVal: '',
      search_venue_name: '',
      venueSearchResult: [],
      venue_type: 'ONSITE',
      venue_search_in_progress: false,
      venue_selected: false,
      venue_details: null,
      preparedTimezones: timezones,
    };
  },
  methods: {
    searchVenueFunction() {
      if (!this.search_venue_name) return;
      Venues.search(!this.search_venue_name).then((result) => {
        this.venueSearchResult = result.data.results;
      });
    },
    doShowCreateVenueModal() {
      this.showCreateVenueModal = true;
    },
    onModalClosed(param) {
      if (param != null) {
        this.setVenue(param);
      }
      this.showCreateVenueModal = false;
    },

    setTimezone() {
      if (this.event.timezone === null) {
        this.event.timezone = 'UTC';
      }
      moment.tz.setDefault(this.event.timezone);
    },

    setVenueType(type) {
      this.venue_type = type;
      this.event.venueType = type;
    },

    setVenue(venue) {
      // console.log("setting venue", venue)
      this.event.venue = venue;
      if (!venue || venue == null) {
        this.venue_details = null;
        this.venue_selected = false;
        this.event.venueType = this.venue_type;
        return;
      }
      this.event.timezone = venue.timezone || 'UTC';
      this.venue_selected = true;
      this.setTimezone();
      this.venue_search_in_progress = true;

      Venues.detail(venue.id).then(
        (res) => {
          this.venue_search_in_progress = false;
          this.venue_details = res.data.data.getVenue;
          this.event.venueType = this.venue_type;
          if (this.venue_details.specialVenue == true) {
            this.specialVenueVal = this.venue_details;
          }
        },
        () => {
          this.venue_search_in_progress = false;
        }
      );
    },
    async fillSpecialVenues() {
      this.loadingCounter++;
      this.specialVenuesLoaded = false;
      let venues = await Venues.listSpecial();
      this.specialVenuesLoaded = true;
      this.loadingCounter--;
      if (
        venues.data.data.searchVenues &&
        venues.data.data.searchVenues.length
      ) {
        this.specialVenues = venues.data.data.searchVenues;
        return venues.data.data.searchVenues.length;
      }
      return false;
    },
    timezoneFilterFn(val, update) {
      if (val === '') {
        update(() => {
          this.preparedTimezones = timezones;
        });
        return;
      }
      update(() => {
        const needle = val.toLowerCase();
        this.preparedTimezones = timezones.filter(
          (v) => v.toLowerCase().indexOf(needle) > -1
        );
      });
    },
  },
  watch: {
    search_venue_name(val) {
      if (!val) return;
      this.venue_search_in_progress = true;
      Venues.search(val).then((result) => {
        this.venueSearchResult = result.data.data.searchVenues;
        this.venue_search_in_progress = false;
      });
    },
    venue_type(newval) {
      this.event.venueType = newval;
    },
  },
  computed: {
    isValidURL() {
      if (!this.event || !this.event.url) return true;
      var pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z#\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$',
        'i'
      ); // fragment locator
      return !!pattern.test(this.event.url);
    },
    isOnsiteOrHybrid() {
      return ['ONSITE', 'HYBRID'].indexOf(this.venue_type.toUpperCase()) > -1;
    },
    isOnlineOrHybrid() {
      return ['ONLINE', 'HYBRID'].indexOf(this.venue_type.toUpperCase()) > -1;
    },
    isOnline() {
      return ['ONLINE'].indexOf(this.venue_type.toUpperCase()) > -1;
    },
  },
  mounted() {
    this.fillSpecialVenues();

    if (this.event.venue) {
      this.venue_selected = true;
      this.venue_details = this.event.venue;
      this.venue_type = this.event.venueType;
      if (this.event.venue.specialVenue) {
        this.specialVenueVal = this.event.venue;
      }
    }
  },
  created() {},
};
